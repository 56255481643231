import styled from 'styled-components';

export const FooterStyles = styled.footer`
  padding: 40px 50px 80px;
  background-color: var(--blue-dark);
  color: var(--white);
  @media (max-width: 1023px) {
    padding: 40px 25px 50px;
  }
  .box {
    &-top {
      @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
    }
    &-contact {
      .footer-logo {
        @media (max-width: 1023px) {
          width: 125px;
          height: 75px;
        }
      }
      .contact-info {
        margin-top: 25px;
        @media (min-width: 1024px) {
          margin-top: 40px;
        }
        address {
          margin-bottom: 10px;
        }
        span, a, address {
          display: block;
          color: var(--white);
        }
      }
    }
    &-socials {
      a {
        display: inline-block;
        margin: 25px 30px 0 0;
        @media (min-width: 1024px) {
          margin: 0 0 0 25px;
        }
        img {
          @media (max-width: 1023px) {
            width: 30px;
            height: 35px;
          }
        }
        &.facebook img {
          width: 25px;
          @media (max-width: 1023px) {
            width: 22px;
          }
        }
        &.x img{
          width: 40px;
          @media (max-width: 1023px) {
            width: 35px;
          }
        }
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid var(--white);
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 24px;
        flex-direction: column-reverse;
        gap: 15px;
      }
      
      .footer-nav {
        a {
          display: inline-block;
          color: var(--white);
          margin-left: 0;
          margin-right:  min(5vw, 30px);
          @media (min-width: 1024px) {
            margin-left: min(4vw, 50px);
            margin-right: 0;
          }
        }
      }
    }
  }
`;