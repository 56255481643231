import React from 'react';
import { FooterStyles } from '../styles/FooterStyles';
import footer_logo from '../assets/images/footer_logo.svg';

// Social media
import facebook from '../assets/images/socialmedia/facebook.svg';
import instagram from '../assets/images/socialmedia/instagram.svg';
import linkedin from '../assets/images/socialmedia/linkedin.svg';
import x from '../assets/images/socialmedia/x.svg';
import whatsapp from '../assets/images/socialmedia/whatsapp.svg';

const socialMediaSrc = [
  {facebook},
  {instagram},
  {linkedin},
  {x},
  {whatsapp}
];



export default function Footer({ data , pageContext }) {
  
  return (
    <FooterStyles>
      <div className="box-top">
        <div className="box-contact">
          <img
            className="footer-logo"
            src={footer_logo}
            alt=""
            width="166"
            height="100"
          />
          <div className="contact-info">
            <span>AB Digital Enterprises</span>
            <address>
              <span>{data.address_line1}</span>
              <span>{data.address_line2}</span>
            </address>
            <a href={"mailto:" + data.email}>{data.email}</a>
            <a href={"tel:" + data.phone}>{data.phone}</a>
          </div>
        </div>
        <div className="box-socials">
          {data.social_media_list &&
            data.social_media_list.map((social, index) => (
              <a key={"social_" + index}
                 href={social.link}
                 target="_blank"
                 rel="noreferrer"
                 className={social.website}
                 aria-label={`${social.website} opens in new tab`}
              >
                <img
                  className="social-media-logo"
                  src={Object.values(...socialMediaSrc.filter(item => item[social.website]))[0]}
                  alt={`${social.website} logo`}
                  width="35"
                  height="40"
                />
              </a>
            ))}
        </div>

      </div>
      <div className="box-bottom">
        <span>©{new Date().getFullYear()} AB Digital  Enterprises. All rights reserved</span>
        <div className="footer-nav">
          {data.footer_nav_list &&
          data.footer_nav_list.map((item, index) => (
            <a href={item.link} key={"footer_nav_" + index}>{item.title[pageContext]}</a>
          ))}
        </div>
      </div>
    </FooterStyles>
  );
}
