import React, {createElement, useContext, useState} from "react";
import useDemoInfo from "../../utils/useDemoInfo";
import FormContext, { useForm } from "../../components/FormContext";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {ContactSection} from '../../styles/ContactStyles'

const translations = {
  en: {
    Submit: 'Submit',
    RequiredField: 'This field is required',
    RequestError: 'An error occurred while sending the request',
    RequestSuccess: 'Thank you for submitting the contact form. We will review your message and get back to you shortly.',
  },
  fr: {
    Submit: 'Soumettre',
    RequiredField: 'Ce champ est obligatoire',
    RequestError: "Une erreur s'est produite lors de l'envoi de la demande",
    RequestSuccess: "Merci d'avoir soumis le formulaire de contact. Nous examinerons votre message et vous répondrons sous peu.",
  },
};
function getTranslation(key, locale) {
  return translations[locale] && translations[locale][key] ? translations[locale][key] : translations['en'][key];
};

export default function ContactForm({ data, pageContext, grid }) {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const regulationContent = data.regulations[pageContext][0]._rawChildren;
  const useFormContext  = useContext(FormContext) || {}; // Obsługa przypadku, w którym FormContext nie jest zdefiniowany

  const { state, handleChange} = useForm();
  const [formSent, setFormSent] = useState(false);
  const [reqCheckbox, setReqCheckbox] = useState(false);
  const [reqEmail, setReqEmail] = useState(false);
  const {fullname, email, phone, msg, accept} = state;
  const { submitDemoRequest, loading, message, error } = useDemoInfo({ values: useFormContext.state, locale: pageContext});


  const handleDemoRequest = async (event) => {
    event.preventDefault();
    const token = await executeRecaptcha('homepage');

    try {
      await submitDemoRequest(event,token)
    } catch (error) {
      console.error(getTranslation('RequestError', pageContext), error);
    }

    setFormSent(true);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!useFormContext.state.accept) {
      setReqCheckbox(true)
    }
    if (!useFormContext.state.email) {
      setReqEmail(true)
    }
    else {
      setReqCheckbox(false)
      setReqEmail(false)
      await handleDemoRequest(event);
    }
  }

  return (
    <ContactSection id={`grid-${grid}`}>
      <div>
        <h2>{data.title[pageContext]}</h2>
        <p>{data.description[pageContext]}</p>
      </div>
      <div>
        {!error && !formSent && (
          <form onSubmit={onSubmit} noValidate>
            <fieldset>
              <div className="field">
                <label htmlFor="input_fullname">Full name</label>
                <input id="input_fullname"
                       type="text"
                       value={fullname}
                       onChange={(e) => handleChange("fullname", e.currentTarget.value)}
                />
              </div>
              <div className="field_2cols">
                <div className={`field ${reqEmail ? 'invalid' : ''}`}>
                  <label htmlFor="input_email">
                    <span>
                      Email *
                    </span>
                    {reqEmail && (<p className="invalid">{getTranslation('RequiredField', pageContext)}</p>)}
                  </label>
                  <input id="input_email"
                         type="email"
                         value={email}
                         onChange={(e) => {
                           handleChange("email", e.currentTarget.value);
                           setReqEmail(!e.currentTarget.value)
                         }}
                  />
                </div>
                <div className="field">
                  <label htmlFor="input_phone">Phone</label>
                  <input id="input_phone"
                         type="tel"
                         value={phone}
                         onChange={(e) => handleChange("phone", e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="input_msg">Message</label>
                <textarea id="input_msg"
                          value={msg}
                          onChange={(e) => handleChange("msg", e.currentTarget.value)}
                />
              </div>
              <div className={`field regulation ${reqCheckbox ? 'invalid' : ''}`}>
                <label htmlFor="input_accept">
                  <span>
                    {
                      regulationContent &&
                      regulationContent.map((item, index) => (
                        createElement(item._type, {key: `accept_${index}`}, item.text)
                      ))
                    } *
                  </span>
                  {reqCheckbox && (<p className="invalid">{getTranslation('RequiredField', pageContext)}</p>)}
                </label>
                <input
                  type="checkbox"
                  id="input_accept"
                  value={accept}
                  onChange={(e) => {
                    handleChange("accept", e.currentTarget.checked);
                    setReqCheckbox(!e.currentTarget.checked)
                  }}
                  required
                />
              </div>
              <button type="submit" className="button primary" disabled={loading}>
                {getTranslation('Submit', pageContext)}
              </button>
            </fieldset>
          </form>
        )}
        {!error && formSent && message && (<div><h5>{getTranslation('RequestSuccess', pageContext)}</h5><p>{message}</p></div>)}
        {error && formSent && message && (<div><h5>{getTranslation('RequestError', pageContext)}</h5><p>{message}</p></div>)}
      </div>
    </ContactSection>
  )
}